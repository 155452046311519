import React, { useEffect, useState } from "react";
import ModalTemplate from "../../../components/modals/ModalTemplate";
import FlexRowBetween from "../../../components/structural/FlexRowBetween";
import ClientSelect from "../../clients/components/ClientSelect";
import TextField from "../../../components/ui/TextField";
import DateField from "../../../components/ui/DateField";
import NumberField from "../../../components/ui/NumberField";
import SelectField from "../../../components/ui/SelectField";
import ModalButtons from "../../../components/modals/ModalButtons";
import useSubscriptions from "../hooks/useSubscriptions";
import Button from "../../../components/ui/Button";
import Table from "../../../components/Table";
import IncomeModal from "../../income/components/IncomeModal";
import { useSelector } from "react-redux";
import { formattedDate } from "../../../utils/formattedDate";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";

export default function SubscriptionsModal({ type, setShowModal, editSubscription }) {
	const [showPaymentModal, setShowPaymentModal] = useState(false);
	const [subscription, setSubscription] = useState({
		id: "",
		client: "",
		description: "",
		startDate: new Date(),
		endDate: null,
		fee: 0,
		feeCurrency: "",
		billingFrequency: "",
		status: "",
	});

	const transactions = useSelector((state) => state.transactions);

	const subscriptionTransactions = transactions.filter(
		(transaction) => transaction.associatedSubscription === subscription.id
	);

	const { isLoading, actionSuccessful, createSubscription, updateSubscription } =
		useSubscriptions();

	const onSaveHandler = async () => {
		if (type === "NEW") {
			await createSubscription(subscription);
		} else if (type === "EDIT") {
			await updateSubscription(subscription);
		}
	};

	const onCloseHandler = () => {
		setShowModal(false);
	};

	// if type is edit effect adds the subscription to edit into state
	useEffect(() => {
		if (type === "EDIT") {
			setSubscription({
				id: editSubscription.id,
				client: editSubscription.associatedClient,
				description: editSubscription.description,
				startDate: editSubscription.startDate ? new Date(editSubscription.startDate) : new Date(),
				endDate: editSubscription.endDate ? new Date(editSubscription.endDate) : null,
				fee: editSubscription.fee / 100,
				feeCurrency: editSubscription.feeCurrency,
				billingFrequency: editSubscription.billingFrequency,
				status: editSubscription.status,
			});
		}
	}, [editSubscription, type]);

	useEffect(() => {
		if (actionSuccessful) {
			setShowModal(false);
		}
	}, [actionSuccessful, setShowModal]);

	return (
		<ModalTemplate
			title={type === "NEW" ? "Create Subscription" : "Subscription"}
			onClose={onCloseHandler}
		>
			<div className='flex gap-4 grid-cols-2 w-full px-4'>
				<ClientSelect
					value={subscription.client}
					onChange={(e) => setSubscription({ ...subscription, client: e.target.value })}
				/>
				<TextField
					label='Description'
					value={subscription.description}
					onChange={(e) => setSubscription({ ...subscription, description: e.target.value })}
					placeholder={"Enter description"}
				/>
			</div>
			<div className='flex gap-4 grid-cols-3 w-full px-4'>
				<DateField
					label='Start Date'
					value={subscription.startDate}
					onChange={(date) => setSubscription({ ...subscription, startDate: date })}
				/>
				<DateField
					label='End Date'
					value={subscription.endDate}
					onChange={(date) => setSubscription({ ...subscription, endDate: date })}
				/>
				<SelectField
					label='Status'
					value={subscription.status}
					options={[{ value: "Active", label: "Active" }]}
					onChange={(e) => setSubscription({ ...subscription, status: e.target.value })}
					placeholder={"Select status"}
				/>
			</div>
			<div className='flex gap-4 grid-cols-4 w-full px-4'>
				<NumberField
					label='Fee'
					value={subscription.fee}
					onChange={(e) => setSubscription({ ...subscription, fee: e.target.value })}
				/>
				<SelectField
					label='Currency'
					value={subscription.feeCurrency}
					options={[
						{ value: "GBP", label: "GBP" },
						{ value: "USD", label: "USD" },
					]}
					onChange={(e) => setSubscription({ ...subscription, feeCurrency: e.target.value })}
					placeholder={"Select currency"}
				/>
				<SelectField
					label='Billing Frequency'
					value={subscription.billingFrequency}
					options={[
						{ value: "Weekly", label: "Weekly" },
						{ value: "Monthly", label: "Monthly" },
						{ value: "Yearly", label: "Yearly" },
					]}
					onChange={(e) => setSubscription({ ...subscription, billingFrequency: e.target.value })}
					placeholder={"Select billing frequency"}
				/>
			</div>
			<Button label='Add Payment' onClick={() => setShowPaymentModal(true)} />
			<div className='w-full my-4'>
				<Table
					columns={[
						{ title: "Value" },
						{ title: "Date" },
						{ title: "Category" },
						{ title: "Actions" },
					]}
					rows={subscriptionTransactions.map((transaction) => ({
						fields: [
							{
								value: `£${(transaction.value / 100).toFixed(2)}`,
							},
							{
								value: formattedDate(transaction.dateTransaction),
							},
							{
								value: transaction.category,
							},
							{
								value: (
									<div className='flex items-center justify-center space-x-4'>
										<button>
											<DeleteOutlined />
										</button>
										<button>
											<EditOutlined />
										</button>
									</div>
								),
							},
						],
					}))}
				/>
			</div>

			<ModalButtons
				buttonOne={{ label: "Save", onClick: () => onSaveHandler(), disabled: isLoading }}
				buttonTwo={{ label: "Cancel", onClick: () => onCloseHandler(), disabled: isLoading }}
			/>
			{showPaymentModal && (
				<IncomeModal
					type='NEW'
					setShowModal={setShowPaymentModal}
					category={"SUBSCRIPTION"}
					clientId={subscription.client}
					subscriptionId={subscription.id}
				/>
			)}
		</ModalTemplate>
	);
}
